<template>
  <div class="page-content" v-if="topic">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Listening Topic Detail"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Level {{ topic.display_order + 1 }}</h2>
      <topic-card :topic="topic" />
    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteTopic"
      :is-deleting="isDeleting"
      :is-disabled-update="checkDuration"
    />
  </div>
</template>

<script>
import service from "../service";
import TopicCard from "./_components/TopicCard.vue";

export default {
  components: {
    TopicCard,
  },
  data() {
    return {
      topic: null,
      isUpdating: false,
      isDeleting: false,
    };
  },
  computed: {
    topic_id() {
      return this.$route.params.topic_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Listening Topic List",
          to: { name: "listening-topic-list" },
        },
      ];
      items.push({ text: "Listening Topic Detail", active: true });
      return items;
    },
    checkDuration() {
      return this.topic?.content?.find((item) => {
        return item.conversations.find(
          (conversation) => conversation.duration < 1
        );
      });
    },
  },
  async created() {
    await this.getTopic();
  },
  methods: {
    async getTopic() {
      this.topic = await service.get({ id: this.topic_id });
    },
    async update() {
      this.isUpdating = true;
      await service.update({ data: JSON.stringify(this.topic) });
      this.isUpdating = false;
      this.getTopic();
    },
    async deleteTopic() {
      this.isDeleting = true;
      await service.delete({ id: this.topic_id });
      this.isDeleting = false;
      this.$router.push({ name: "listening-topic-list" });
    },
  },
};
</script>
